import './src/css/index.css';

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!prevLocation && !location.hash) {
    // do not restore scroll position on page refresh without hash
    setTimeout(
      () => window.scrollTo({ top: 0, left: 0, behavior: 'instant' }),
      0,
    );
  }

  const excludedPaths = [
    '/free-trial',
    '/request-a-demo',
    '/contact-us',
    '/smallcon',
  ];
  const currentPath = location.pathname;

  // Remove existing script if it exists
  const existingScript = document.getElementById(
    'forethought-widget-embed-script',
  );
  if (existingScript) {
    existingScript.remove();
  }

  // Hide chat box if it exists
  const chatBox = document.getElementById('forethought-chat');
  if (chatBox) {
    chatBox.style.display = 'none';
    chatBox.remove();
  }

  // Check if the current path starts with any of the excluded paths
  const isExcluded = excludedPaths.some(
    (path) => currentPath === path || currentPath.startsWith(`${path}/`),
  );

  if (!isExcluded) {
    // Load script only after first scroll
    let hasScrolled = false;
    const handleScroll = () => {
      if (!hasScrolled) {
        hasScrolled = true;
        const script = document.createElement('script');
        script.src = 'https://solve-widget.forethought.ai/embed.js';
        script.id = 'forethought-widget-embed-script';
        script.setAttribute(
          'data-api-key',
          '9bb09c16-62fb-4768-b9c8-c538aa69a0c7',
        );
        script.setAttribute(
          'data-ft-Embed-Script-Language',
          'ENTER-VALUE-HERE',
        );
        document.body.appendChild(script);

        // Remove scroll listener after loading
        window.removeEventListener('scroll', handleScroll);
      }
    };
    window.addEventListener('scroll', handleScroll);
  }
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.history.scrollRestoration = 'manual';
  const currentPosition = getSavedScrollPosition(location) || [0, 0];

  setTimeout(() => {
    if (location.hash) {
      // If hash (`/#something`), smooth scroll to that position
      const item = document.querySelector(`${location.hash}`);
      item?.scrollIntoView({ behavior: 'smooth' });
    } else {
      // when using the browser back/forward buttons or navigating to a different page client-side, instant scroll to its last saved position
      window.scrollTo({
        top: currentPosition[1],
        left: currentPosition[0],
        behavior: 'instant',
      });
    }
  }, 0);

  // do not let gatsby do anything more
  return false;
};
